// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-forgiveness-js": () => import("./../../../src/pages/forgiveness.js" /* webpackChunkName: "component---src-pages-forgiveness-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurance-and-fees-js": () => import("./../../../src/pages/insurance-and-fees.js" /* webpackChunkName: "component---src-pages-insurance-and-fees-js" */),
  "component---src-pages-techniques-js": () => import("./../../../src/pages/techniques.js" /* webpackChunkName: "component---src-pages-techniques-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

